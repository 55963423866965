import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { paragonMigration } from '../query';

export const useParagonMigration = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['paragonMigration', entityRef];

  const ParagonMigrate = () =>
    useMutation({
      mutationFn: ({ data }) => paragonMigration.paragonMigrate({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { ParagonMigrate };
};
