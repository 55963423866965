import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useDefaultRoute } from 'connex-cds';

import AuthenticatedRoutes from 'views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from 'views/authenticated-routes/RedirectToEntity';

import Home from 'views/Home';
import TracesView from 'components/traces';
import ParagonMigrationView from 'components/paragon-migration';

export const AppRoutes = () => {
  const defaultRoute = useDefaultRoute('traces');

  return (
    <Routes>
      <Route path="" element={<AuthenticatedRoutes />}>
        <Route path="" element={<RedirectToEntity />}>
          <Route path=":entityRef/*" element={<Home />}>
            <Route path="traces" element={<TracesView />} />
            <Route path="paragon-migration" element={<ParagonMigrationView />} />
            <Route path="*" element={<Navigate to={defaultRoute} />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
