export default {
  sidebarOptions: [
    {
      id: 'traces',
      labelStringId: 'traces',
      path: '/traces',
      iconName: 'Edit',
    },
    {
      id: 'paragon-migration',
      labelStringId: 'paragon_migration',
      path: '/paragon-migration',
      iconName: 'BusAlert',
    },
  ],
};
