import { useMemo } from 'react';

const useQueryControls = queryControls => {
  const controls = useMemo(() => {
    const searchControl = queryControls.find(control => control.type === 'search') || null;
    const filterControl = queryControls.find(control => control.type === 'filter') || null;
    const dateControl = queryControls.find(control => control.type === 'date') || null;

    return { searchControl, filterControl, dateControl };
  }, [queryControls]);

  return controls;
};

export default useQueryControls;
