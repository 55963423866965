import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuItem as MUIMenuItem } from '@mui/material';

export const MigrationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;
  width: 100%;
  padding: 1rem 0;

  > div {
    flex: 24%;
    max-width: 24%;
    padding: 0px;

    > div {
      display: flex;
      flex-grow: 2;
      margin: 2rem 1rem;
      min-width: 15%;
      justify-content: center;
      align-items: center;

      > div {
        width: 100%;
      }
    }

    > button {
      margin: 0 1rem;
    }
  }
`;

export const NothingToShowWrapper = styled.div`
  padding: 2%;
  display: flex;
  justify-content: center;
`;

export const MenuItem = styled(MUIMenuItem)(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2.25, 2)};
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: 400;
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};

    &:hover,
    &.Mui-selected:hover {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.neutral[40] })};
    }

    &.Mui-selected {
      font-weight: 500;
      background-color: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.darkBlue[60] })};
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    }
  `
);
