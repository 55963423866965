import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Select,
  TextField,
  ToggleButton,
} from '@mui/material';
import { useParagonMigration } from 'app-entities/trace/api';
import { Button } from 'core';
import React, { useCallback, useEffect, useState } from 'react';
import { MigrationContainer, MenuItem } from './style';

const INITIAL_STATE = {
  dataCounts: {
    classes: '',
    aliases: '',
    items: '',
    plants: '',
  },
  migratedCounts: {
    classes: '',
    aliases: '',
    items: '',
    plants: '',
  },
  exclusions: {
    classes: false,
    aliases: false,
    items: false,
    plants: false,
  },
};

const ParagonMigrationView = () => {
  const [tenants, setTenants] = useState(null);
  const [tenantId, setTenantId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dataCounts, setDataCounts] = useState(INITIAL_STATE.dataCounts);
  const [exclusions, setExclusions] = useState(INITIAL_STATE.exclusions);
  const [migrationResult, setMigrationResult] = useState('');
  const [migratedCounts, setMigratedCounts] = useState(INITIAL_STATE.migratedCounts);
  const [message, setMessage] = useState('');
  const { ParagonMigrate } = useParagonMigration();
  const { isLoading, error, mutateAsync: paragonMigrate } = ParagonMigrate();

  const onUsernameChange = useCallback(
    event => {
      setUsername(event?.target?.value);
    },
    [setUsername]
  );

  const onPasswordChange = useCallback(
    event => {
      setPassword(event?.target?.value);
    },
    [setPassword]
  );

  const onTenantIdChange = useCallback(
    event => {
      setTenantId(event?.target?.value);
    },
    [setTenantId]
  );

  const onVerifyClick = useCallback(async () => {
    setMigrationResult('');
    setMessage('');
    setMigratedCounts(INITIAL_STATE.migratedCounts);
    setDataCounts(INITIAL_STATE.dataCounts);
    const result = await paragonMigrate({ data: { username, password, tenantId, readOnly: true } });
    if (!result) {
      return;
    }
    setMessage(result.message);
    setDataCounts({
      classes: result.concreteClassCount,
      aliases: result.concreteClassAliasCount,
      items: result.concreteItemCount,
      plants: result.plantCount,
    });
  }, [paragonMigrate, password, tenantId, username]);

  const onExclusionsChange = useCallback(
    (exclusion, event, checked) => {
      setExclusions({ ...exclusions, [exclusion]: checked });
    },
    [exclusions]
  );

  const onMigrateClick = useCallback(async () => {
    setMigrationResult('');
    const result = await paragonMigrate({ data: { username, password, tenantId, readOnly: false, exclusions } });
    setMigrationResult(result?.success ? 'Migration successful!' : 'Migration failed.');
    setMessage(result?.message);
    setMigratedCounts({
      classes: result?.concreteClassCount || 0,
      aliases: result?.concreteClassAliasCount || 0,
      items: result?.concreteItemCount || 0,
      plants: result?.plantCount || 0,
    });
  }, [exclusions, paragonMigrate, password, tenantId, username]);

  useEffect(() => {
    async function fetchData() {
      if (tenants?.length) {
        return;
      }

      const result = await paragonMigrate({ data: {} });
      if (!result) {
        return;
      }
      result.tenants.sort((a, b) => a.displayName.localeCompare(b.displayName));
      setTenants(result.tenants);
    }
    fetchData();
  }, [paragonMigrate, tenants]);

  const ExcludeCheckbox = ({ type }) => {
    return (
      <FormControlLabel
        id={`exclude-${type}-label`}
        label="Exclude"
        style={{ width: '97%' }}
        control={
          <Checkbox
            checked={exclusions[type]}
            onChange={(event, checked) => onExclusionsChange(type, event, checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
      />
    );
  };

  return (
    <Box className="tabs-content">
      <Box className="content-container">
        <Box className="content-header"></Box>
        <MigrationContainer className="content-body">
          <div>
            <FormControl>
              <InputLabel id="tenant-label">Tenant</InputLabel>
              <Select labelId="tenant-label" label="Tenant" value={tenantId} onChange={onTenantIdChange} required>
                {tenants?.map(tenant => (
                  <MenuItem value={tenant.tenantId}>{tenant.displayName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField label="Username" value={username} onChange={onUsernameChange} />
            <TextField label="Password" value={password} onChange={onPasswordChange} type="password" />
            <Button className="settings-button" onClick={onVerifyClick}>
              Lookup
            </Button>
          </div>
          <div>
            <FormControl>
              <TextField label="Concrete Classes" value={dataCounts.classes} readOnly />
              <ExcludeCheckbox type="classes" />
            </FormControl>
            <FormControl>
              <TextField label="Concrete Class Aliases" value={dataCounts.aliases} readOnly />
              <ExcludeCheckbox type="aliases" />
            </FormControl>
            <FormControl>
              <TextField label="Concrete Items" value={dataCounts.items} readOnly />
              <ExcludeCheckbox type="items" />
            </FormControl>
            <FormControl>
              <TextField label="Plants" value={dataCounts.plants} readOnly />
              <ExcludeCheckbox type="plants" />
            </FormControl>
            <Button onClick={onMigrateClick} disabled={!dataCounts.classes && !dataCounts.aliases}>
              Migrate
            </Button>
          </div>
          <div>
            <>
              <Box>
                <h2>{migrationResult}</h2>
              </Box>
              <Box>
                <p>
                  {message}
                  <br />
                  {migrationResult && `${migratedCounts.classes} of ${dataCounts.classes} concrete classes migrated.`}
                  <br />
                  {migrationResult &&
                    `${migratedCounts.aliases} of ${dataCounts.aliases} concrete class aliases migrated.`}
                  <br />
                  {migrationResult && `${migratedCounts.items} of ${dataCounts.items} concrete items migrated.`}
                  <br />
                  {migrationResult && `${migratedCounts.plants} of ${dataCounts.plants} plants migrated.`}
                </p>
              </Box>
            </>
          </div>
        </MigrationContainer>
      </Box>
    </Box>
  );
};

export default ParagonMigrationView;
