import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  selectCompany: 'Select Company',
  companies: 'Companies',
  profiles: 'Profiles',
  apps: 'Apps',
  roles: 'Roles',
  createRole: 'Create Role',
  permissions: 'Permissions',
  status: 'Status',
  services: 'Services',
  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',
  all: 'All',

  displayRowCount: `Displaying {count, plural,
    =0 {0 rows}
    one {# row}
    other {# rows}
  }`,

  columnFilters: 'Column Filters',

  type: 'Type',
  id: 'ID',
  hostname: 'Hostname',
  name: 'Name',
  apiKey: 'API Key',
  origin: 'Origin',
  servicesListHeader: 'Services',
  profilesListHeader: 'Profiles',
  'add-services': 'Add Service',
  'add-profiles': 'Add Profile',
  servicesEditor: 'Service Editor',
  profileEditor: 'Profile Editor',

  add: 'Add',
  cancel: 'Cancel',

  crn: 'CRN',
  timeZone: 'Time Zone',
  modifyDate: 'Last Modified',
  active: 'Active',
  entitiesListHeader: 'Companies',
  'add-entities': 'Add Company',
  entitiesEditor: 'Company Editor',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  verified: 'Verified',
  createDate: 'Registered On',
  'custom:eulaVersion': 'EULA Version',
  'custom:privacyVersion': 'Privacy Version',
  enabled: 'Enabled',

  notAuthorized: 'Not Authorized',

  general: 'General',
  general_cancel: 'Cancel',
  isTestEntity: 'Test Company',
  password: 'Password',
  createProfile: 'Create Profile',
  createEntity: 'Create Company',
  users: 'Users',
  profilesEditor: 'Profiles Editor',
  delete: 'Delete',
  update: 'Update',

  save: 'Save',
  visible: 'Visible',
  hidden: 'Hidden',
  gridPreferences: 'Grid Preferences',
  partners: 'Partners',
  'add-partners': 'Add Partner',
  partnersListHeader: 'Partners',
  partnersEditor: 'Partners Editor',

  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',

  dexas: 'DExAs',
  dashboard: 'Dashboard',
  install: 'Install',
  master_configs: 'Master Configs',
  queries: 'Queries',
  query_sets: 'Query Sets',
  traces: 'Traces',
  paragon_migration: 'Paragon Migration',
  code: 'Verification Code',
  username: 'Username',
  familyName: 'Last Name',
  givenName: 'First Name',
  phoneNumber: 'Phone Number',
  verifyPassword: 'Verify Password',

  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',

  required: 'Required',
  general_minimumZero: 'Min value is 0',
  general_tooSmallValue: 'Too small value',

  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",

  'admin-permission': 'Administrator',
  'app-permissions': 'App Permissions',

  'company-admin': 'Company Admin',
  'billing-exp': 'Billing',
  'dashboards-exp': 'Dashboards',
  assurance: 'Assurance',
  'cxp-admin': 'CP Admin',
  mt: 'Deliveries',
  admin: 'Platform Admin',
  support: 'Platform Support',

  general_confirm: 'Confirm',

  general_dialogAction_save: 'Save',

  general_notification_copy: 'Copied to clipboard',

  emptyState_highlight: 'No results found',
  emptyState_text: 'Try adjusting your search or filter to find what you are looking for.',

  settings_header: 'Settings',
  general_tableHeader_id: 'ID',
  dexa_tableHeader_company: 'Company',
  dexa_tableHeader_lastPollDateTime: 'Last Poll',
  dexa_tableHeader_modifyDate: 'Last Modified',
  dexa_tableHeader_lastAuthor: 'Last Author',
  dexa_tableHeader_originCode: 'Origin',
  dexa_tableHeader_activeConfig: 'Active Config',

  dexas_addDexa: 'Add DExA',
  dexas_editDexaTitle: 'Edit DExA',
  dexas_profileTab_title: 'DExA',
  dexas_profileTab_id_field_label: 'ID',
  dexas_profileTab_name_field_label: 'Name',
  dexas_profileTab_status_field_label: 'Status',

  trace_tableHeader_traceTypeId: 'Type',
  trace_tableHeader_traceLevelId: 'Level',
  trace_tableHeader_sourceSystemId: 'Source',
  trace_tableHeader_sourceSystemTypeId: 'Source Type',
  trace_tableHeader_objectId: 'Object ID',
  trace_tableHeader_objectTypeId: 'Object Type',
  trace_tableHeader_modifyDate: 'Last Modified',

  dateSelector_past_5_minutes: 'Past 5 Minutes',
  dateSelector_past_15_minutes: 'Past 15 Minutes',
  dateSelector_past_30_minutes: 'Past 30 Minutes',
  dateSelector_past_hour: 'Past Hour',
  dateSelector_past_3_hours: 'Past 3 Hours',
  dateSelector_past_6_hours: 'Past 6 Hours',
  dateSelector_today: 'Today',
  dateSelector_yesterday: 'Yesterday',
  dateSelector_custom: 'Custom',

  datePicker_start: 'Start Date',
  datePicker_end: 'End Date',

  gridHeaderMenu_menu_label: 'Menu',
  gridHeaderMenu_unsort_label: 'Unsort',
  gridHeaderMenu_asc_label: 'Sort by ASC',
  gridHeaderMenu_desc_label: 'Sort by DESC',
  gridHeaderMenu_sort_label: 'Sort',
  gridHeaderMenu_hide_column_label: 'Hide column',
  gridHeaderMenu_manage_columns_label: 'Manage columns',
  gridHeaderMenu_find_column_label: 'Find column',
  gridHeaderMenu_search_column_label: 'Column title',
  gridHeaderMenu_show_all_label: 'Show all',
  gridHeaderMenu_hide_all_label: 'Hide all',
  gridHeaderMenu_show_columns_label: 'Columns',
  gridFooter_count: 'Displaying',
  gridFooter_countRecord: `{value, plural,
    =0 {records}
    one {record}
    other {records}
    }`,
  gridDetailHeader_eventName: 'Event Name',
  gridDetailHeader_traceLevelId: 'Level',
  gridDetailHeader_timestamp: 'Timestamp',
  gridDetailHeader_details: 'Details',

  master_data: 'Master Data',
  general_save: 'Save',


  company: 'Company',

  traceLevelFilter: 'Trace Level',
  traceLevelFilter_all: 'All',
  traceLevelFilter_error: 'Error',

  errors,
  themes,
};
