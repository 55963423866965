import React from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';

export const Currency = ({ value, currency, decimalPlaces }) => {
  const options = {
    currency,
    style: 'currency',
    minimumFractionDigits: decimalPlaces ?? 2,
    maximumFractionDigits: decimalPlaces ?? 2,
  };

  return <FormattedNumber value={value} {...options} />;
};

export const useCurrency = () => {
  const { formatNumber } = useIntl();
  const { userLocale } = useLocalizationContext();

  const getCurrencyParts = React.useCallback(
    ({ currency, locale }) => {
      const value = 1234.56;
      const numberFormat = new Intl.NumberFormat(locale || userLocale, { style: 'currency', currency });

      const parts = numberFormat.formatToParts(value);

      const partsWeCareAbout = {
        currency: 'currencySymbol',
        group: 'thousandsSeparator',
        decimal: 'decimalSeparator',
      };

      return parts.reduce((acc, part) => {
        const partType = partsWeCareAbout[part.type];
        if (partType) {
          acc[partType] = part.value;
        }
        return acc;
      }, {});
    },
    [userLocale]
  );

  // TODO: currency should come from entity settings.
  const getCurrency = React.useCallback(
    (value, currency, options = {}) => {
      const preparedOptions = {
        currency,
        style: 'currency',
        minimumFractionDigits: options?.decimalPlaces ?? 2,
        maximumFractionDigits: options?.decimalPlaces ?? 2,
      };

      return formatNumber(value, preparedOptions);
    },
    [formatNumber]
  );

  return { Currency, getCurrency, getCurrencyParts };
};
