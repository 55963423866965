import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';
import { getTimeZoneString } from './Timezone';

const time = ({ date, formatTime, formatMessage, timeZone, messages, hideTimeZone, userLocale, hour12 = undefined }) => {
  if (!date) return '';

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
    hour12,
  };

  const dateTime = toDateTime(date, timeZone);

  const timeZoneString = getTimeZoneString({ dateTime, timeZone, messages, userLocale, formatMessage });

  const formattedTime = formatTime(dateTime, options);

  return hideTimeZone ? formattedTime : `${formattedTime} (${timeZoneString})`;
};

export const Time = ({ date, hideTimeZone = false, testId }) => {
  const { formatTime, formatMessage } = useIntl();
  const { timeZone, messages, userLocale } = useLocalizationContext();
  const formattedTime = time({ date, formatTime, formatMessage, timeZone, messages, hideTimeZone, userLocale });

  return <span data-testid={testId}>{formattedTime}</span>;
};

export const useTime = ({ overrideTimeZone = undefined, hour12 = undefined } = {}) => {
  const { formatTime, formatMessage } = useIntl();
  const { timeZone, messages, userLocale } = useLocalizationContext();

  const getTime = React.useCallback(
    (date, hideTimeZone = false) => {
      return time({
        date,
        formatTime,
        formatMessage,
        timeZone: overrideTimeZone ?? timeZone,
        messages,
        hideTimeZone,
        userLocale,
        hour12,
      });
    },
    [formatMessage, formatTime, messages, timeZone, overrideTimeZone, userLocale]
  );

  return { getTime, Time };
};
